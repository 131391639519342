import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___it",
    path: "/it/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/es-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/it-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]